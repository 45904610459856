import Vue from "vue";
import VueRouter from "vue-router";
import VueClipboard from "vue-clipboard2";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import Cookies from "js-cookie";
import OrgTree from "zm-tree-org"
import "zm-tree-org/lib/zm-tree-org.css"
import GlobalService from "@/utils/httpservice";
import store from "@/utils/vuex";
export const editionType = process.env.VUE_APP_TERMINAL_EDITION;

Vue.use(VueRouter);
Vue.use(OrgTree);
Vue.use(Element, {
    size: Cookies.get("size") || "medium",
    zIndex: 2000,
});
// 是否为开发环境, 注:开发环境下强制开启性能分析 和 日志与警告信息呈现
let isDevelopmentEnv = false;
if (
    process.env.VUE_APP_ABLE_DEBUG == undefined ||
    process.env.VUE_APP_ABLE_DEBUG == true
) {
    // 如果环境变量中未设置 VUE_APP_ABLE_DEBUG 或者 设置为true时, 开启开发模式配置
    isDevelopmentEnv = true;
}
// 关闭 提示 You are running Vue in development mode. Make sure to turn on production mode when deploying for production.
Vue.config.productionTip = false;
// 关闭 提示 Download the Vue Devtools extension for a better development experience:
Vue.config.devtools = false;
// 在浏览器开发工具的性能/时间线面板中启用对组件初始化、编译、渲染和打补丁的追踪 需要安装 Vue Performance Devtool 这个 Chrome 插件
Vue.config.performance = isDevelopmentEnv;
// 用来配置是否取消 Vue 的所有日志与警告 为 true 时，Vue 将不再输出任何日志与警告信息。
Vue.config.silent = !isDevelopmentEnv;
// 剪贴板功能导入
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.prototype.$rightAside = {
    download:{
        count:0,
        data:[
            {
                model:"account",
                code:"ahejsklsllsls",
                name:"下载name"
            },
        ]
    }
}
// 如果终端编码信息不存在时,判定所有信息全部失效
var TerminalCode = localStorage.getItem("Terminal-Code") 
if (TerminalCode== undefined || TerminalCode== null || TerminalCode == "") {
    localStorage.clear();
}
// localStorage.clear();//清空sm数据, 后继判断时,需要判断是否登录, 如果登录的不需要清空
// await GlobalService.GenerateSM2Keys();
// await GlobalService.registTerminal();
await GlobalService.GenerateSM2Keys();
await GlobalService.registTerminal();
let routerArray = await GlobalService.getRooter();
const rootRouter = await new VueRouter({
    mode: "history", //此模式下不会出现#号结尾,
    routes: routerArray,
});
rootRouter.beforeEach((to, from, next) => {
    const accessToken = localStorage.getItem("Access-Token");
    if (to.path != "/login" && (accessToken == "" || accessToken == null || accessToken == undefined)) {
        next({
            path: "/login",
            query: { redirect: to.fullPath },
        });
    } else {
        next();
    }
});

rootRouter.afterEach(to=> {
    if (to.name ) {
        const processTitle = process.env.VUE_APP_TITLE || "控制台"
        window.document.title = `${to.name?to.name:processTitle}`
    }
});

new Vue({
    el: "#app",
    store: store,
    router: rootRouter,
    render: (h) => h(App),
});