var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"zm-tree-org",staticClass:"zm-tree-org"},[_c('div',{ref:"zoom",staticClass:"zoom-container",class:{'is-center': _vm.center && !_vm.horizontal},style:(_vm.zoomStyle),on:{"wheel":_vm.zoomWheel}},[_c('zm-draggable',{class:{ dragging: _vm.autoDragging },attrs:{"x":_vm.left,"y":_vm.top,"draggable":_vm.draggable,"drag-cancel":_vm.dragCancel},on:{"dragging":_vm.onDrag,"dragstop":_vm.onDragStop}},[_c('div',{ref:"tree-org",staticClass:"tree-org",class:{horizontal: _vm.horizontal, collapsable: _vm.collapsable}},[_c('tree-org-node',{directives:[{name:"nodedrag",rawName:"v-nodedrag.l.t",value:(_vm.nodeargs),expression:"nodeargs",modifiers:{"l":true,"t":true}}],key:_vm.nodeKey,attrs:{"data":_vm.data,"props":_vm.keys,"lazy":_vm.lazy,"suffix":_vm.suffix,"horizontal":_vm.horizontal,"label-style":_vm.labelStyle,"collapsable":_vm.collapsable,"default-expand-level":_vm.defaultExpandLevel,"render-content":_vm.renderContent,"label-class-name":_vm.labelClassName},on:{"on-expand":_vm.handleExpand,"on-node-click":_vm.handleClick,"on-node-dblclick":_vm.handleDblclick,"on-node-mouseenter":_vm.nodeMouseenter,"on-node-mouseleave":_vm.nodeMouseleave,"on-node-contextmenu":_vm.nodeContextmenu,"on-node-focus":function (e, data) { _vm.$emit('on-node-focus', e, data)},"on-node-blur":_vm.handleBlur},scopedSlots:_vm._u([(_vm.$scopedSlots.default)?{key:"default",fn:function(ref){
var node = ref.node;
return [_vm._t("default",null,{"node":node})]}}:null,(_vm.$scopedSlots.expand)?{key:"expand",fn:function(ref){
var node = ref.node;
return [_vm._t("expand",null,{"node":node})]}}:null],null,true)})],1)])],1),(_vm.tools)?[_c('div',{staticClass:"zm-tree-handle"},[(_vm.tools.scale)?_c('div',{staticClass:"zm-tree-percent"},[_vm._v(_vm._s(_vm.zoomPercent))]):_vm._e(),(_vm.tools.expand)?_c('div',{staticClass:"zm-tree-handle-item",attrs:{"title":_vm.expandTitle},on:{"click":_vm.expandChange}},[_c('span',{staticClass:"zm-tree-svg"},[_c('i',{class:['treefont', _vm.expanded ? 'icon-collapse' : 'icon-expand']})])]):_vm._e(),(_vm.tools.zoom)?_c('div',{staticClass:"zm-tree-handle-item zoom-out",attrs:{"title":"放大"},on:{"click":_vm.enlargeOrgchart}},[_c('span',{staticClass:"zm-tree-icon"},[_vm._v("+")])]):_vm._e(),(_vm.tools.zoom)?_c('div',{staticClass:"zm-tree-handle-item zoom-in",attrs:{"title":"缩小"},on:{"click":_vm.narrowOrgchart}},[_c('span',{staticClass:"zm-tree-icon"},[_vm._v("-")])]):_vm._e(),(_vm.tools.restore)?_c('div',{staticClass:"zm-tree-handle-item",attrs:{"title":"还原"},on:{"click":_vm.restoreOrgchart}},[_c('span',{staticClass:"zm-tree-restore"})]):_vm._e(),(_vm.tools.fullscreen)?_c('div',{staticClass:"zm-tree-handle-item",attrs:{"title":_vm.fullTiltle},on:{"click":_vm.handleFullscreen}},[_c('span',{staticClass:"zm-tree-svg"},[_c('i',{class:['treefont', _vm.fullscreen ? 'icon-unfullscreen' : 'icon-fullscreen']})])]):_vm._e()])]:_vm._e(),(_vm.nodeDraggable)?_c('clone-org',{directives:[{name:"show",rawName:"v-show",value:(_vm.nodeMoving),expression:"nodeMoving"}],attrs:{"props":_vm.keys,"data":_vm.cloneData,"horizontal":_vm.horizontal,"label-style":_vm.labelStyle,"collapsable":_vm.collapsable,"render-content":_vm.renderContent,"label-class-name":_vm.labelClassName},scopedSlots:_vm._u([(_vm.$scopedSlots.default)?{key:"default",fn:function(ref){
var node = ref.node;
return [_vm._t("default",null,{"node":node})]}}:null,(_vm.$scopedSlots.expand)?{key:"expand",fn:function(ref){
var node = ref.node;
return [_vm._t("expand",null,{"node":node})]}}:null],null,true)}):_vm._e(),(_vm.nodeMenus.length)?_c('zm-contextmenu',{attrs:{"visible":_vm.contextmenu,"x":_vm.menuX,"y":_vm.menuY,"node":_vm.menuData,"data":_vm.data,"props":_vm.keys,"menus":_vm.nodeMenus,"disabled":_vm.disabled,"node-add":_vm.nodeAdd,"node-delete":_vm.nodeDelete,"node-edit":_vm.nodeEdit,"node-copy":_vm.nodeCopy},on:{"update:visible":function($event){_vm.contextmenu=$event},"contextmenu":function (arg) { _vm.$emit('on-contextmenu', arg) },"on-node-copy":function (txt) { _vm.$emit('on-node-copy', txt)},"on-node-delete":function (node, parentNode) { _vm.$emit('on-node-delete', node, parentNode)}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }